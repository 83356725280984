import { type ParsedQuery } from 'query-string'
import { lazy, Suspense } from 'react'

import { Loading } from '../../components/Loading'
import TopNav from '../../components/TopNav'
import { AnalyticsFiltersProvider } from '../shipment-filters/AnalyticsFiltersProvider'
import { ShipmentFilterPanel } from '../shipment-filters/FilterPanel'
import { useHandleFilterChange } from '../shipment-filters/hooks'
import { useAnalyticsParams } from './hooks'
import { lanes, providers, spotQuote, users } from './types'

const LaneAnalytics = lazy(() => import('./LaneAnalytics'))
const ProviderAnalytics = lazy(() => import('./ProviderAnalytics'))
const SpotQuoteAnalytics = lazy(() => import('./SpotQuoteAnalytics'))
const UserReportAnalytics = lazy(() => import('./UserAnalytics'))
export const AnalyticsPage = () => {
  const { view } = useAnalyticsParams()
  const { searchParams, handle: handleFilterChange } = useHandleFilterChange()

  const onFilterChange = (query: ParsedQuery) => {
    handleFilterChange({
      ...query,
      pickupDate: searchParams.get('pickupDate'),
    })
  }
  return (
    <div className="grid-analytics grid bg-fv-gray-100 h-screen grid-cols-[19rem_1fr] grid-rows-[auto_1fr_auto] b1300:grid-cols-1">
      <AnalyticsFiltersProvider>
        <TopNav />

        <ShipmentFilterPanel onChange={onFilterChange} />
        <Suspense
          fallback={
            <>
              <div className="flex items-center justify-center">
                <Loading />
              </div>
            </>
          }
        >
          {view === lanes && <LaneAnalytics />}
          {view === providers && <ProviderAnalytics />}
          {view === spotQuote && <SpotQuoteAnalytics />}
          {view === users && <UserReportAnalytics />}
        </Suspense>
      </AnalyticsFiltersProvider>
    </div>
  )
}
